<template>
  <el-card shadow="never" class="m-1">
    <template #header>
      <el-row type="flex" justify="center" class="mt--5">
        <el-image
          style="width: 350px; height: 100px"
          :src="'/images/logo.png'"
          :fit="'contain'"
        ></el-image>
      </el-row>
      <el-row type="flex" justify="center">
        <small class="small-font"
          >Rua Cel Alcebiades de Miranda, Nº 639. Bairro Boa Vista, Ponta Grossa
          - PR. CEP 84.073-000</small
        >
      </el-row>
      <el-row :gutter="4" type="flex" justify="center">
        <i class="el-icon-message"></i>
        <small class="small-font">donauagronegocios@gmail.com</small>
        <i class="el-icon-phone" style="margin-left: 8px"></i>
        <small class="small-font"> (042) 3227-1744</small>
      </el-row>
      <el-row :gutter="4" type="flex" justify="center"> </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <el-col
          ><el-row type="flex" justify="center"
            ><h4 class="m-0">Relatório de Caixas</h4></el-row
          ></el-col
        >
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <el-col
          ><el-row type="flex" justify="center"
            ><h5 class="m-0">Empresa(s):</h5>
            <h5 class="m-0">{{ getFirms() }}</h5>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      size="mini"
      :cell-style="() => 'text-align:center;'"
      :data="Cashiers"
    >
      <el-table-column prop="firm.name" label="açougue"></el-table-column>
      <el-table-column prop="op" label="operador">
        <template #default="c">
          {{ c?.row?.op }}
        </template>
      </el-table-column>
      <el-table-column label="data" :formatter="(r) => formatDate(r.closed_at)">
      </el-table-column>
      <el-table-column label="geral">
        <template #default="r">
          <div
            :class="{
              'is-negative': calculateTotalGlobal(r.row) < 0,
              'is-positive': calculateTotalGlobal(r.row) >= 0,
            }"
          >
            {{ currencyFormatter.format(calculateTotalGlobal(r.row)) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="finalizadora"
        :formatter="(r) => currencyFormatter.format(Number(r.total_sells) || 0)"
      ></el-table-column>
      <el-table-column
        label="sangria"
        :formatter="(r) => currencyFormatter.format(Number(r.withdrawal) || 0)"
      ></el-table-column>
      <el-table-column
        label="total em caixa"
        prop="total_cashier"
        :formatter="
          (r) => currencyFormatter.format(Number(r.total_cashier) || 0)
        "
      ></el-table-column>
      <el-table-column
        label="conferência"
        prop="total_cashier"
        :formatter="(r) => currencyFormatter.format(Number(r.check_total) || 0)"
      ></el-table-column>
      <el-table-column label="resultado" prop="total_cashier">
        <template #default="r">
          <div
            :class="{
              'is-negative': calculateResult(r.row) < 0,
              'is-positive': calculateResult(r.row) >= 0,
            }"
          >
            {{ currencyFormatter.format(calculateResult(r.row)) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">Total:</p>
      </el-col>
      <el-col :md="3" class="md-3-print"></el-col>
      <el-col :md="3" class="md-3-print"
        ><p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => calculateTotalGlobal(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.total_sells)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.withdrawal)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.total_cashier)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.check_total)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p
          :class="{
            summary: true,
            'is-negative':
              (Cashiers?.map((r) => calculateResult(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0) < 0,
            'is-positive':
              (Cashiers?.map((r) => calculateResult(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0) >= 0,
          }"
        >
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => calculateResult(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
//import Moment from "moment";
//import { ElNotification } from "element-plus";
export default {
  name: "BillsPage",
  data: () => ({
    reports: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormat: new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      style: "decimal",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }),
  }),
  computed: {
    Data() {
      return JSON.parse(this.$route?.query?.data || "[]");
    },
    Cashiers() {
      return this.Data || [];
    },
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    calculateResult(r) {
      return Number(Number(r.total_cashier) - Number(r.check_total)) || 0;
    },
    calculateTotalGlobal(r) {
      return (
        Number(r.total_sells) +
          Number(r.discount) +
          Number(r.canceled) +
          Number(r.start) || 0
      );
    },
    getFirms() {
      const firms = this.Data.map((d) => d.firm);
      const names = firms?.reduce((t, f) => {
        t[f?.name] = true;
        return t;
      }, {});

      return Object.keys(names)
        ?.filter((e) => e != "undefined")
        .join(", ");
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(c);
      else return "desconhecido";
    },
  },
};
</script>
<style>
body {
  -webkit-print-color-adjust: exact;
}
.el-card {
  width: 100%;
}
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 4px !important;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.m-1 {
  margin: 8px !important;
  max-width: 96%;
}
.text-center {
  text-align: center !important;
}
@media print {
  table.el-table__body {
    width: auto !important;
  }
  table.el-table__header {
    width: auto !important;
  }
  div.md-12-print {
    max-width: 49% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
  div.md-3-print {
    max-width: 12.5% !important;
  }
  html,
  body {
    height: 98%;
  }
}
.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #333;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: center;
}
.summary-black {
  color: #909399 !important;
}
</style>